import './dataSelection.scss';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { DateFormat, SessionStatus, Update, fileStatusFlag } from 'constants/constants';
import { NexusCard, NexusChip, NexusIcon, NexusNotification } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import { createFileFromResponse, formatDateSelected } from 'services/services';
import { useAppDispatch } from 'store';

import ButtonComponent from 'app/components/nds/button/button';
import CalendarIcon from '@nexus/core/dist/assets/icons/media/ic_today_24px.svg';
import DatePicker from 'react-date-picker';
import DownloadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import FileUpload from 'app/components/fileUpload/fileUpload';
import InputComponent from 'app/components/nds/input/input';
import ModalComponent from 'app/components/nds/modal/modal';
import ResetIcon from '@nexus/core/dist/assets/icons/media/ic_replay_24px.svg';
import Select from 'app/components/nds/select/select';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import {
    getCanonicalModelById,
    getTestSessionDataSourceTemplate,
    uploadDataSourceTemplate,
    dataSourceTemplateInfo,
    getFile,
    updateTestSession,
    resetUploadedDataSource,
    updateCurrentTestSession,
    updateUserFlow,
    showToastMessage,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import { currentTestSession } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const DataSelection: React.FC = () => {
    const [t] = useTranslation('lang');
    const [intervalID, setIntervalID] = useState<any>(undefined);
    const dispatch = useAppDispatch();

    const testSession = useSelector(currentTestSession);
    const {
        testSessionId,
        dateFrom,
        dateTo,
        testSessionTestCases,
        uploadedDataSourceData,
        canonicalModelData: cannonicalData,
        testSessionIsDraft,
    } = testSession;

    const canonicalModelId = testSessionTestCases?.[0]?.canonicalModelId;

    const savedDateRangeField = testSessionTestCases?.[0]?.testCaseParameters?.filter_by;
    const [dateRangeField, setDateRangeField] = useState<string>(
        savedDateRangeField || t('dataSelection.pleaseSelect'),
    );
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const filterableColumns = cannonicalData?.filterableColumns?.date_range
        ? cannonicalData?.filterableColumns?.date_range.map((val: any) => {
              return {
                  label: val?.title,
                  value: val?.name,
              };
          })
        : [];
    useEffect(() => {
        return () => {
            intervalID !== undefined && clearInterval(intervalID);
        };
    }, [intervalID]);
    useEffect(() => {
        if (uploadedDataSourceData?.status === fileStatusFlag.ready) {
            dispatch(
                showToastMessage({
                    autoClose: 5000,
                    id: 'fileUploadValidation_ready',
                    message: 'Data Validation Complete',
                    type: 'success',
                }),
            );
        }
        if ([fileStatusFlag.invalid, fileStatusFlag.ready].includes(uploadedDataSourceData?.status)) {
            clearInterval(intervalID);
        }
    }, [uploadedDataSourceData?.status]);
    useEffect(() => {
        if (
            testSessionTestCases?.[0]?.canonicalModelId &&
            (!cannonicalData || Object.keys(cannonicalData).length === 0)
        ) {
            dispatch(getCanonicalModelById(testSessionTestCases?.[0]?.canonicalModelId));
        }
    }, [testSessionTestCases[0]]);
    useEffect(() => {
        if (
            uploadedDataSourceData?.id &&
            ![fileStatusFlag.invalid, fileStatusFlag.ready].includes(uploadedDataSourceData?.status)
        ) {
            triggerTestSessionUpdate(uploadedDataSourceData.id);
        } else {
            uploadedDataSourceData?.id && dispatch(dataSourceTemplateInfo(uploadedDataSourceData.id));
        }
    }, [uploadedDataSourceData?.id]);
    const handleNextButton = () => {
        const updatedTestSessionTestCases = testSessionTestCases.map((item: any) => {
            return {
                testCaseId: item.id || item.testCaseId,
                testCaseParameters: {
                    filter_by: dateRangeField === t('dataSelection.pleaseSelect') ? null : dateRangeField,
                },
            };
        });
        const payload: { testSessionRequest: any; id: number } = {
            testSessionRequest: {
                dataInputFileId: uploadedDataSourceData?.id || null,
                dateFrom: dateFrom,
                dateTo: dateTo,
                draftStatus: SessionStatus.step4,
                isDraft: testSessionIsDraft !== undefined ? testSessionIsDraft : true,
                testSessionTestCases: updatedTestSessionTestCases,
            },
            id: testSessionId,
        };
        dispatch(updateTestSession(payload)).then((response: any) => {
            if (response.payload.status === 200) {
                dispatch(updateActiveStep(stepperRoutes.step_4));
            }
        });
    };
    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_2));
        dispatch(updateUserFlow(Update));
    };
    const handleUploadData = () => {
        setShowUpload(true);
    };
    const handleDropDown = (value: string) => {
        setDateRangeField(value);
    };
    const closeModal = () => {
        setShowUpload(false);
    };

    //UPLOAD POST CALL
    const triggerTestSessionUpdate = (dataFileId: any) => {
        const updatedTestSessionTestCases = testSessionTestCases.map((item: any) => {
            return {
                ...item,
                testCaseParameters: JSON.stringify({
                    ...item?.testCaseParameters,
                    execution_parameters: item?.scriptConfiguration?.execution_parameters,
                    filter_by: dateRangeField === t('dataSelection.pleaseSelect') ? null : dateRangeField,
                }),
            };
        });
        const payload = {
            testSessionRequest: {
                dataInputFileId: dataFileId,
                draftStatus: SessionStatus.step3,
                isDraft: testSessionIsDraft !== undefined ? testSessionIsDraft : true,
                testSessionTestCase: updatedTestSessionTestCases,
            },
            id: testSessionId,
        };

        // PATCH CALL TO COMMENCE DQ CHECK
        dispatch(updateTestSession(payload)).then((data: any) => {
            if (data && data.payload) {
                pollUploadedFileStatus(dataFileId);
                closeModal();
            }
        });
    };
    //POLLING API
    const pollUploadedFileStatus = (dataFileId: any) => {
        let count = 0;
        const id = setInterval(() => {
            ++count;
            if (count >= 60) {
                intervalID !== undefined && clearInterval(intervalID);
                return;
            }
            // To do- Need to remove after validation
            dispatch(dataSourceTemplateInfo(dataFileId));
        }, 5000);
        setIntervalID(id);
    };

    const handleReset = () => {
        intervalID !== undefined && clearInterval(intervalID);
        dispatch(resetUploadedDataSource({}));
    };
    const downLoadErrorFile = () => {
        dispatch(getFile(uploadedDataSourceData?.dqResultsFileId)).then((response: any) => {
            if (response && response.payload) {
                createFileFromResponse(response);
            }
        });
    };
    const Inprogress = () => {
        return (
            <div className='nexus-row nexus-mt-1 inValidation'>
                <div className='loader-styles nexus-ml-1' />
                <div className={'nexus-subtitle-sm nexus-ml-2 nexus-mt-2'}>
                    {t('dataSelection.validationInProgress')}
                </div>
            </div>
        );
    };
    const Failed = () => {
        return (
            <div className='nexus-row nexus-mt-1 failedValidation'>
                <NexusNotification variant='error' className='nexus-ml-1 top-align1' />
                <div className={'nexus-subtitle-sm nexus-ml-1 top-align2'}>{t('dataSelection.validationFailed')}</div>
                {uploadedDataSourceData?.dqResultsFileId && (
                    <>
                        <NexusIcon src={DownloadIcon} className='top-align2 nexus-ml-1' size='sm'></NexusIcon>
                        <div className={'nexus-body-sm reset'} onClick={() => downLoadErrorFile()}>
                            <u>{t('dataSelection.downloadError')}</u>
                        </div>
                    </>
                )}
                {uploadedDataSourceData?.validationErrors && (
                    <div className={'nexus-body-sm reset'}>{uploadedDataSourceData.validationErrors}</div>
                )}
            </div>
        );
    };
    const ResetComponent = () => {
        return (
            <div>
                <div className='nexus-row nexus-mt-1 left-align'>
                    <NexusIcon src={ResetIcon} className='nexus-mt-1' size='sm'></NexusIcon>
                    <div className={'nexus-body-sm reset'} onClick={() => handleReset()} data-testid={'reset-button'}>
                        <u>{t('dataSelection.resetToDefault')}</u>
                    </div>
                </div>
            </div>
        );
    };
    const handleDateReset = () => {
        setDateRangeField(t('dataSelection.pleaseSelect'));
        const payload = {
            dateFrom: null,
            dateTo: null,
        };
        dispatch(updateCurrentTestSession(payload));
    };
    const ResetDateComponent = () => {
        return (
            <div>
                <div className='nexus-row nexus-mt-4 left-align'>
                    <NexusIcon src={ResetIcon} className='nexus-mt-1' size='sm'></NexusIcon>
                    <div
                        className={'nexus-body-sm reset'}
                        onClick={() => handleDateReset()}
                        data-testid={'reset-button'}
                    >
                        <u>{t('dataSelection.resetToDefault')}</u>
                    </div>
                </div>
            </div>
        );
    };
    const handleDownloadFile = () => {
        dispatch(getTestSessionDataSourceTemplate(canonicalModelId))?.then((response: any) => {
            if (response && response.payload && response.payload.status >= 200 && response.payload.status < 300) {
                createFileFromResponse(response);
            }
        });
    };
    const handleUpload = (queue: any) => {
        const payload = {
            file: queue[0],
            id: canonicalModelId,
        };

        dispatch(uploadDataSourceTemplate(payload)).then((response: any) => {
            if (response && response.payload && response.payload.status >= 200 && response.payload.status < 300) {
                dispatch(
                    showToastMessage({
                        autoClose: 7000,
                        id: 'fileUploadValidation_uploaded_session',
                        message: 'Data validation in progress, you will be notified when document is ready',
                        type: 'info',
                    }),
                );
            } else {
                closeModal();
            }
        });
    };
    const updateDateFields = (date: any, type: string) => {
        dispatch(updateCurrentTestSession({ [type]: formatDateSelected(date) }));
    };
    return (
        <div data-testid='data-selection'>
            {showUpload && (
                <ModalComponent
                    show={showUpload}
                    closeModal={closeModal}
                    header={t('dataSelection.uploadData')}
                    size='lg'
                    extraClass='header-alignment'
                >
                    <FileUpload
                        closeModal={closeModal}
                        handleDownloadFile={handleDownloadFile}
                        handleUpload={handleUpload}
                    />
                </ModalComponent>
            )}
            <NexusCard className='nexus-card-border-0 nexus-p-3'>
                <div className='nexus-subtitle nexus-mb-1'>{t('dataSelection.header1')}</div>
                <div className='nexus-caption-copy'>{t('dataSelection.subHeader1')}</div>
                <div className='nexus-caption-copy nexus-mb-1'>{t('dataSelection.subHeader2')}</div>
                <div className='nexus-row'>
                    <div className='nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xl-3 nexus-mt-1'>
                        <InputComponent
                            type='text'
                            placeholder={cannonicalData?.name}
                            data-testid='input-data-source'
                            disabled={true}
                            initialValue={cannonicalData?.name}
                        />

                        {/* IN PROGRESS COMPONENT */}
                        {(uploadedDataSourceData?.status === fileStatusFlag.validation ||
                            uploadedDataSourceData?.status === fileStatusFlag.uploaded) && <Inprogress />}
                        {/* FAILED COMPONENT */}
                        {uploadedDataSourceData?.status === fileStatusFlag.invalid && <Failed />}
                        {/* RESET COMPONENT */}
                        {uploadedDataSourceData?.name && <ResetComponent />}
                    </div>
                    <div className='nexus-col-lg-1 nexus-col-xl-1 nexus-col-2xl-1'>
                        <ButtonComponent
                            type={['nexus-btn nexus-btn-medium']}
                            label={t('buttons.uploadData')}
                            click={handleUploadData}
                        />
                    </div>
                    <div className='nexus-col-lg-2 nexus-col-xl-2 nexus-col-2xl-2 nexus-mt-1 nexus-ml-5'>
                        {uploadedDataSourceData?.name && (
                            <NexusChip removable={false} key={uploadedDataSourceData.name}>
                                {uploadedDataSourceData?.name}
                            </NexusChip>
                        )}
                    </div>
                </div>

                <>
                    <div className='nexus-subtitle nexus-mt-1 nexus-mb-1 nexus-mt-2'>{t('dataSelection.header2')}</div>
                    <div className='nexus-caption-copy nexus-mb-1'>{t('dataSelection.subHeader3')}</div>
                    <div className='nexus-row nexus-mb-1'>
                        <div className='nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2 nexus-col-xl-2 nexus-col-2xl-2'>
                            <div className='nexus-data nexus-mb-1' data-testid='label-date-from'>
                                {t('dataSelection.dateFrom')}
                            </div>
                            <DatePicker
                                value={dateFrom}
                                onChange={(date: any) => {
                                    updateDateFields(date, 'dateFrom');
                                }}
                                disabled={
                                    uploadedDataSourceData?.id || dateRangeField === t('dataSelection.pleaseSelect')
                                }
                                data-testid='input-textbox-date-from'
                                format={process.env.REACT_APP_DATE_FORMAT}
                                monthPlaceholder={DateFormat.monthPlaceholder}
                                dayPlaceholder={DateFormat.dayPlaceholder}
                                yearPlaceholder={DateFormat.yearPlaceholder}
                                className='date-picker'
                                maxDate={dateTo ? new Date(dateTo) : undefined}
                                calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                nativeInputAriaLabel='date-test-from'
                            />
                        </div>
                        <div className='nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-2 nexus-col-lg-2 nexus-col-xl-2 nexus-col-2xl-2 '>
                            <div className='nexus-data nexus-mb-1' data-testid='label-date-to'>
                                {t('dataSelection.dateTo')}
                            </div>
                            <DatePicker
                                value={dateTo}
                                onChange={(date: any) => {
                                    updateDateFields(date, 'dateTo');
                                }}
                                disabled={
                                    uploadedDataSourceData?.id || dateRangeField === t('dataSelection.pleaseSelect')
                                }
                                data-testid='input-textbox-date-to'
                                format={process.env.REACT_APP_DATE_FORMAT}
                                monthPlaceholder={DateFormat.monthPlaceholder}
                                dayPlaceholder={DateFormat.dayPlaceholder}
                                yearPlaceholder={DateFormat.yearPlaceholder}
                                className='date-picker'
                                minDate={dateFrom ? new Date(dateFrom) : undefined}
                                calendarIcon={<NexusIcon src={CalendarIcon} size='sm' />}
                                nativeInputAriaLabel='date-test-to'
                            />
                        </div>
                        <div className='nexus-col-2xs-1 nexus-col-xs-1 nexus-col-sm-1 nexus-col-md-3 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xl-3 date-range'>
                            <div className='nexus-data nexus-mb-1'>{t('dataSelection.subHeader4')}</div>
                            <Select
                                key={
                                    dateRangeField === t('dataSelection.pleaseSelect') && filterableColumns[0]?.value
                                        ? 'dateRangeField'
                                        : dateRangeField
                                }
                                disabled={uploadedDataSourceData?.id ? true : false}
                                options={[...filterableColumns]}
                                customOnChange={handleDropDown}
                                selectedValue={dateRangeField || t('dataSelection.pleaseSelect')}
                                disabledOption={t('dataSelection.pleaseSelect')}
                            />
                        </div>
                        {(dateFrom ||
                            dateTo ||
                            (dateRangeField !== undefined && dateRangeField !== t('dataSelection.pleaseSelect'))) && (
                            <ResetDateComponent />
                        )}
                    </div>
                </>

                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        <div className='nexus-mr-2'>
                            <ButtonComponent
                                type={['nexus-btn nexus-rhythm-top-5']}
                                label={t('buttons.back')}
                                click={handleBackButton}
                            />
                        </div>
                        <div className='nexus-mr-1'>
                            <ButtonComponent
                                extraClass='nexus-btn-default'
                                type={['nexus-btn-primary nexus-rhythm-top-5']}
                                label={t('buttons.next')}
                                click={handleNextButton}
                                disabled={(!dateTo || !dateFrom) && dateRangeField !== t('dataSelection.pleaseSelect')}
                            />
                        </div>
                    </div>
                </div>
            </NexusCard>
        </div>
    );
};

export default DataSelection;
