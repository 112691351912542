import CheckIcon from '@nexus/core/dist/assets/icons/action/ic_done_24px.svg';
import CrossIcon from '@nexus/core/dist/assets/icons/action/ic_close_24px.svg';
import DraftIcon from '@nexus/core/dist/assets/icons/action/ic_edit_24px.svg';

export const homePageData = 'homePageData';
export const riskControlPageData = 'riskControlPageData';
export const limit = 10;
export const RiskControlDropdown = 'riskControlDropdown';
export const Replace = 'Replace';
export const Reject = 'Reject';
export const RiskControlHeaderPage = 'RiskControlHeaderPage';
export const PleaseSelect = 'Select';
export const pleaseSelect = 'Please Select';

export const breadcrumbData = [
    { isEnabled: false, name: 'Home', path: '/home' },
    { isEnabled: false, name: 'Create New Session' },
];

export const active = 'active';
export const defaultStepper = 'default';
export const complete = 'complete';
export const LandingPage = 'LandingPage';
export const TestTemplate = 'TestTemplate';

export enum viewResultsHeader {
    initialized = 'example=test_results_initialized',
    processed = 'example=test_results_processed',
    historical = 'example=test_results_historical',
}

export const sessionHeader = 'example=sessions_with_morgage';
export const enterKey = 'Enter';
export const View = 'View';

export const DateToId = 'nexus-date-to';
export const DateFromId = 'nexus-date-from';
export const RiskDropdown = 'RiskDropdown';
export const ControlDropdown = 'ControlDropdown';
export enum handleAllDropdowns {
    Sector = 'Sector',
    Lob = 'Lob',
    Process = 'Process',
    Risk = 'Risk',
    Control = 'Control',
}
export const Create = 'Create';
export const Update = 'Update';
export const NewFromExisting = 'NewFromExisting';

export const ASC = 'ASC';
export const DESC = 'DESC';

export const string = 'string';
export const number = 'number';
export const text = 'text';
export const integer = 'integer';
export const input_class = 'input-color';

export const riskValue = [
    { id: '123', name: 'Loan Eligibility' },
    { id: '333', name: 'Test' },
];
export const controlValue = [{ id: '456', name: 'Customer due diligence / Enhanced due diligence' }];
export const ConfirmationPage = 'ConfirmationPage';
export enum userProfile {
    Analyst = 'AT-Test-Analyst',
    TestDesigner = 'AT-Test-Designer',
}
export enum TEST_FLOW_TYPE {
    TestSession = 'TestSession',
    TestTemplate = 'TestTemplate',
}
export const completeBadge = 'Complete';
export const inProgress = 'In Progress';
export const Fail = 'Fail';
export const draft = 'Draft';
export const Warning = 'Warning';
export const Info = 'Info';
export const once = 'once';
export const doesNotRepeat = 'Does Not Repeat';
export const asterik = '*';
export const projectId = 44000257;
export const manualTableHeader = [
    {
        field: 'COLUMN_NAME',
        id: 'COLUMN_NAME',
        isSortable: false,
        label: 'COLUMN NAME',
        minWidth: 50,
    },
    {
        field: 'DATA_TYPE',
        id: 'DATA_TYPE',
        isSortable: false,
        label: 'DATA TYPE',
        minWidth: 30,
    },
    {
        field: 'SAMPLE_VALUE',
        id: 'SAMPLE_VALUE',
        isSortable: false,
        label: 'SAMPLE VALUE',
        minWidth: 80,
    },
    {
        field: 'AVAILABLE_OPERATORS',
        id: 'AVAILABLE_OPERATORS',
        isSortable: false,
        label: 'AVAILABLE OPERATORS',
        minWidth: 60,
    },
];

export const OperatorOptions = [
    { label: '>', type: ['number', 'integer'], value: '>' },
    { label: '<', type: ['number', 'integer'], value: '<' },
    { label: '==', type: ['number', 'integer', 'string'], value: '==' },
    { label: '<=', type: ['number', 'integer'], value: '<=' },
    { label: '>=', type: ['number', 'integer'], value: '>=' },
    { label: '!=', type: ['number', 'integer', 'string'], value: '!=' },
];
export const Complete = 'Complete';
export const InProgress = 'In Progress';
export const ErrorBoundaries = 'Error Boundary';
export const UploadModal = 'Upload Modal';
export enum Status {
    All = 'ALL',
    Published = 'PUBLISHED',
    Draft = 'DRAFT',
}
export enum SessionStatus {
    step1 = 'step_1',
    step2 = 'step_2',
    step3 = 'step_3',
    step4 = 'step_4',
    step5 = 'step_5',
}

export enum DateFormat {
    format = 'yyyy-MM-dd',
    monthPlaceholder = 'MM',
    dayPlaceholder = 'DD',
    yearPlaceholder = 'YYYY',
}

export const DataSelectionTable = 'DataSelectionTable';

export enum fileStatusFlag {
    validation = 'validation',
    invalid = 'invalid',
    uploaded = 'uploaded',
    fileValid = 'file_valid ',
    ready = 'ready',
}
export enum tabOptions {
    option1 = 'option1',
    option2 = 'option2',
}

export const EXPRESSION = 'expression';
export const MVEL = 'mvel';

export enum roleNavigation {
    Analyst = '/test-session/session',
    Designer = '/test-template/session',
}

export const statusDetails: any = [
    { bg: '#ffd6cc', icon: CrossIcon, key: 'failed' },
    { bg: '#ffebb2', icon: DraftIcon, key: 'inProgress' },
    { bg: '#B3F3AF', icon: CheckIcon, key: 'passed' },
];
export const error = 'error';
export const VALID = 'valid';
export const INVALID = 'invalid';

export enum DELETE_MODAL_TYPE {
    DELETE = 'delete',
    BULK_DELETE = 'bulkDelete',
}

export const pageSizeOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 100, value: 100 },
];
