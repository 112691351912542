export const getDropdownValues = (dropdownValues: any) => {
    const modifiedValues: any =
        dropdownValues?.length > 0 &&
        dropdownValues.reduce((acc: any, data: any) => {
            const existingData = acc.find((item: any) => item.name === data.name);
            if (!existingData) {
                acc.push(data);
            }
            return acc;
        }, []);

    return (
        modifiedValues?.length > 0 &&
        modifiedValues?.map((data: any) => {
            return {
                id: data.id || '',
                label: data.name || '',
                value: data.name || '',
            };
        })
    );
};
export const getSelectedId = (dropdownData: any, formData: any) => {
    return dropdownData?.filter((data: any) => data.value === formData)[0]?.id;
};

export const getMultiSelectValues = (multiSelectValues: any) => {
    if (multiSelectValues !== undefined) {
        const modifiedValues: any =
            multiSelectValues?.length > 0 &&
            multiSelectValues.reduce((acc: any, data: any) => {
                const existingData = acc.find((item: any) => item.name === data.name);
                if (!existingData) {
                    acc.push(data);
                }
                return acc;
            }, []);

        return modifiedValues?.length > 0
            ? modifiedValues?.map((data: any) => {
                  return {
                      id: data.id,
                      isSelected: false,
                      label: data?.name || '',
                      value: data.id,
                  };
              })
            : [];
    }
};

export const formatDateTable = (date: string) => {
    const [month, day, year] = date.split('/');
    const formattedDate = `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
    return formattedDate;
};

export const formatDateSelected = (date: any) => {
    if (date instanceof Date) {
        const dateStr = date.toLocaleDateString('en-US');
        const [month, day, year] = dateStr.split('/');
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return formattedDate;
    }
    return undefined;
};

export const formatSavedDate = (date: any) => {
    const dateObject = new Date(date);
    return dateObject;
};

export const downloadFile = (blob: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
};
export const createFileFromResponse = async (response: any) => {
    const type = response.payload.headers['content-type'];
    const name = response.payload.headers['content-disposition'];

    const blob: any = new Blob([response.payload.data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name ? name.split('=')[1] : 'file.csv';
    const loader = document.createElement('div');
    loader.innerText = 'Generating CSV file...';
    document.body.appendChild(loader);

    downloadFile(blob, name ? name.split('=')[1] : 'file.csv');
    loader.remove();
};

export const getCurrentFormatedDate = (date: any) => {
    return `${date.getFullYear()}${
        date.getMonth() + 1
    }${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
};
export const getUniqueLabelKey = (props: any) => {
    let combinedString = '';
    for (const prop in props) {
        combinedString += props[prop] + '-';
    }
    return combinedString;
};

export function isObjectEmpty(obj: object): boolean {
    if (!obj) {
        return true;
    }
    return Object.keys(obj).length === 0;
}
