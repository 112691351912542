import './index.scss';
import { useState, useRef, useCallback, useEffect } from 'react';
import { NexusIcon } from '@nexus/react';
import CheckIcon from '@nexus/core/dist/assets/icons/action/ic_done_24px.svg';
import ErrorIcon from '@nexus/core/dist/assets/icons/alert/ic_error_24px.svg';
import InfoIcon from '@nexus/core/dist/assets/icons/alert/ic_info_24px.svg';
import WarningIcon from '@nexus/core/dist/assets/icons/alert/ic_warning_24px.svg';
import BadgeComponent from 'app/components/nds/badge/badge';
import { completeBadge, Fail, Warning, Info } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import {
    getNotifications,
    getNotificationsCount,
    readAllNotifications,
    readANotification,
    updateNotifications,
} from './redux/notificationsReducer';
import { isLoading as loading, notificationsData, notificationsMetaData } from './redux/notificationsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateTime } from 'utils/actionUtils';
import ServerEvents from 'services/serverEvents';
import { notificationStreamApi } from 'constants/apiConstants';
import { selectAuthToken } from 'auth';

interface Props {
    title: string;
    open: boolean;
    onClose: () => void;
}

const Notifications: React.FC<Props> = ({ title = '', onClose, open }) => {
    const [t] = useTranslation('lang');
    const dispatch = useDispatch();
    const [beforeId, setBeforeId] = useState('');
    const [expand, setExpand] = useState(open);
    const [hide, setHide] = useState(true);
    const [initialRender, setInitialRender] = useState(true);
    const isLoading = useSelector(loading);
    const notifications = useSelector(notificationsData);
    const notificationsMeta = useSelector(notificationsMetaData);
    const accessToken = useSelector(selectAuthToken);
    const { oldestNotificationId } = notificationsMeta;
    const lastNotificationId = notifications?.[notifications?.length - 1]?.id;
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        ServerEvents({
            accessToken,
            dispatchData: (data: any) => {
                dispatch(updateNotifications(data));
            },
            url: notificationStreamApi,
        });
    }, []);

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            setHide(true);
        } else {
            setHide(false);
            setExpand(open);
        }
    }, [open]);

    useEffect(() => {
        const payload = {
            afterId: '',
            beforeId,
            limit: 10,
        };
        open && dispatch(getNotifications(payload));
        setHasMore(lastNotificationId !== oldestNotificationId);
    }, [beforeId]);

    const observer: any = useRef();

    const lastElement = useCallback(
        (node: any) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            setHasMore(lastNotificationId !== oldestNotificationId);
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setBeforeId(notifications?.[notifications?.length - 1]?.id);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, hasMore, notifications],
    );

    useEffect(() => {
        const payload = {
            afterId: '',
            beforeId: '',
            limit: 10,
        };
        open && dispatch(getNotifications(payload));
    }, [open]);

    const readAllNotificationsList = () => {
        dispatch(readAllNotifications());
        onClose();
    };

    const readNotification = (item: any) => {
        if (!item.isRead) {
            dispatch(readANotification(item.id));
            dispatch(getNotificationsCount());
        }
    };

    const getIcon = (type: string) => {
        switch (type) {
            case 'success':
                return <NexusIcon size='md' src={CheckIcon} className='complete-icon' />;
            case 'error':
                return <NexusIcon size='md' src={ErrorIcon} className='fail-icon' />;
            case 'warning':
                return <NexusIcon size='md' src={WarningIcon} className='warning-icon' />;
            default:
                return <NexusIcon size='md' src={InfoIcon} className='info-icon' />;
        }
    };

    const getBadge = (status: string) => {
        switch (status) {
            case 'success':
                return <BadgeComponent color='complete-status' label={completeBadge} />;
            case 'error':
                return <BadgeComponent color='fail-status' label={Fail} />;
            case 'warning':
                return <BadgeComponent color='in-progress-status' label={Warning} />;
            default:
                return <BadgeComponent color='info-status' label={Info} />;
        }
    };

    return (
        <div
            data-testid='notifications-container'
            className={`notifications-container  ${hide ? 'hide' : expand ? 'open' : 'close'}`}
        >
            <div className='header-container'>
                <div className='title'>{title}</div>
                <div data-testid='read-all' onClick={readAllNotificationsList}>
                    <span className='dismiss'>{t('buttons.markAllRead')}</span>
                </div>
            </div>
            <div className='card-section'>
                {notifications?.length
                    ? notifications.map((item: any, index: number) => {
                          return (
                              <div
                                  ref={notifications.length === index + 1 ? lastElement : null}
                                  className={`notification-card ${!item.isRead && 'unread'}`}
                                  onClick={() => readNotification(item)}
                                  data-testid='notification-card'
                              >
                                  <div className='notification-details'>
                                      <div>{getIcon(item.notificationType)}</div>
                                      <div className='nexus-ml-1 width'>
                                          <div className='notification-title'>
                                              <span>{item.title}</span>
                                              <span className='notification-time'>
                                                  {formatDateTime(item.createdAt)}
                                              </span>
                                          </div>
                                          {getBadge(item.notificationType)}
                                          <div className='notification-message'>{item.message}</div>
                                          <div className='nexus-mt-2'>
                                              <a
                                                  type='button'
                                                  href={`/test-session-details/${item?.relatedEntityId}`}
                                                  className='see-more'
                                              >
                                                  {t('buttons.seeMore')}
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : null}
                {isLoading && <p>Loading...</p>}
            </div>
        </div>
    );
};
export default Notifications;
