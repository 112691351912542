import EventSource from 'react-native-sse';

interface IProps {
    url: string;
    accessToken: string;
    dispatchData: any;
}

const ServerEvents = async ({ url, accessToken, dispatchData }: IProps) => {
    try {
        const es = new EventSource(url, {
            headers: {
                Authorization: {
                    toString: function () {
                        return 'Bearer ' + accessToken;
                    },
                },
            },
        });
        es.addEventListener('open', (event) => {
            // eslint-disable-next-line no-console
            console.log('Open SSE connection.', event);
        });
        es.addEventListener('message', (event) => {
            event.data !== 'Connection Established' && dispatchData(event.data);
        });
        es.addEventListener('error', (event) => {
            if (event.type === 'error') {
                // eslint-disable-next-line no-console
                console.error('Connection error:', event.message);
            } else if (event.type === 'exception') {
                // eslint-disable-next-line no-console
                console.error('Error:', event.message, event.error);
            }
        });
        es.addEventListener('close', () => {
            // eslint-disable-next-line no-console
            console.log('Close SSE connection.');
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error in ServerEvents', e);
    }
};

export default ServerEvents;
