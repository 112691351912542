import './templateSelection.scss';
import { useAppDispatch } from 'store';
import { useEffect, useState } from 'react';
import CustomTable from 'app/components/nds/customTable/customTable';
import LoaderComponent from 'app/components/nds/loader/loader';
import { ASC, DESC, Status } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import { NexusCheckbox } from '@nexus/react';
import { isMainCheckboxSelected } from 'utils/actionUtils';
import { getTemplateSelectionData, updateCurrentTestSession } from '../../redux/testSessionReducer';
import {
    currentTestSession,
    templateSelectionData as templateDataList,
    isLoading as showLoader,
} from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
interface ITemplateSelectionTable {
    searchKeyword: string;
    checkedItems: any[];
    dataSourceId?: Array<number>;
}

const TemplateSelectionTable = ({ checkedItems, searchKeyword, dataSourceId }: ITemplateSelectionTable) => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    //pagination states
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    //sort states
    const [sortCol, setSortCol] = useState<string>('');
    const [sort, setSort] = useState<string>('templateName');

    const testSession = useSelector(currentTestSession);
    const { testSessionTestCases, prc } = testSession || {};
    const templateData = useSelector(templateDataList);
    const { totalElements, content: templateSelectionData } = templateData || {};
    const isLoading = useSelector(showLoader);
    const selectedControls = prc?.control;

    //table headers
    const testTemplateTableHeader = [
        {
            enableCheckBox: true,
            field: 'checkBoxBtn',
            id: 'checkBoxBtn',
            isSortable: false,
            label: '',
            minWidth: 1,
        },
        {
            field: 'name',
            id: 'name',
            isSortable: true,
            label: t('templateSelection.testTemplate'),
            minWidth: 100,
        },
        {
            field: 'description',
            id: 'description',
            isSortable: true,
            label: t('templateSelection.testDescription'),
            minWidth: 220,
        },
        {
            field: 'sector',
            id: 'sector',
            isSortable: true,
            label: t('testParameters.sector'),
            minWidth: 100,
        },
        {
            field: 'dataSource',
            id: 'dataSource',
            isSortable: true,
            label: t('templateSelection.dataSource'),
            minWidth: 100,
        },
    ];
    //table data
    let rowData: Array<any> =
        templateSelectionData !== undefined
            ? templateSelectionData?.map((data: any) => {
                  return {
                      checkBoxBtn: (
                          <>
                              <div className={` nexus-row checkbox-position`}>
                                  <NexusCheckbox
                                      data-testid={`rowCheckBox`}
                                      onClick={() => handleSingleCheckBox(data)}
                                      checked={checkedItems?.includes(data?.testCaseId)}
                                      className='checkbox-styles'
                                  ></NexusCheckbox>
                                  {data?.col}
                              </div>
                          </>
                      ),
                      dataSource: data?.canonicalModel?.name,
                      description: data?.testCaseDescription,
                      name: data?.testCaseName,
                      sector: data?.sector?.name,
                  };
              })
            : [];

    useEffect(() => {
        // add dataSourceId in the payload after the API is ready
        const payload = {
            canonicalModelIds: dataSourceId,
            controlIds: selectedControls || '',
            page: currentPage - 1 < 0 ? 0 : currentPage - 1,
            search: searchKeyword || undefined,
            size: pageSize,
            sort: sort && sortCol ? [sortCol + ',' + sort] : undefined,
            status: Status.Published,
            testCaseCreationDateFrom: undefined,
            testCaseCreationDateTo: undefined,
        };
        dispatch(getTemplateSelectionData(payload));
    }, [searchKeyword, sort, sortCol, currentPage, dataSourceId, pageSize]);

    const paginationHandler = (currentPage: number, currentPageSize: number) => {
        setPageSize(currentPageSize);
        setCurrentPage(currentPage);
    };
    const handleSort = (label: string, field: string) => {
        setSortCol(field);
        if (sortCol === field) {
            setSort(sort === DESC ? ASC : DESC);
        } else {
            setSort(ASC);
        }
    };
    const handleSingleCheckBox = (data: any) => {
        const { testCaseId } = data;
        const isChecked = testSessionTestCases?.find((item: any) => item.testCaseId === testCaseId);
        if (isChecked) {
            dispatch(
                updateCurrentTestSession({
                    testSessionTestCases: testSessionTestCases?.filter((item: any) => item.testCaseId !== testCaseId),
                }),
            );
            return;
        }
        dispatch(
            updateCurrentTestSession({
                testSessionTestCases: [
                    ...testSessionTestCases,
                    {
                        testCaseId,
                    },
                ],
            }),
        );
    };
    const handleAllCheckBox = (event: any) => {
        const checked = event.target.checked;

        if (checked === undefined) return;
        if (checked) {
            dispatch(
                updateCurrentTestSession({
                    testSessionTestCases: templateSelectionData?.reduce((acc: any[], item: any) => {
                        return [...acc, { testCaseId: item?.testCaseId }];
                    }, []),
                }),
            );
            return;
        }
        dispatch(
            updateCurrentTestSession({
                testSessionTestCases: [],
            }),
        );
    };

    return (
        <div data-testid='template-selection-table'>
            {isLoading && <LoaderComponent show={isLoading} fullscreen={true} />}
            <CustomTable
                pagination={true}
                columnsDef={testTemplateTableHeader}
                pageSizeCustom={pageSize}
                max={Math.ceil(totalElements / pageSize)}
                data={rowData}
                paginationFunc={paginationHandler}
                handleSort={handleSort}
                sortIcon={sort}
                sortCol={testTemplateTableHeader.map((e: any) => e?.field).indexOf(sortCol)}
                currentPageNo={currentPage}
                noDataMessage={t('norecordAvailable')}
                handleAllCheckBox={handleAllCheckBox}
                selectAllCheckBox={isMainCheckboxSelected(
                    templateSelectionData || [],
                    testSessionTestCases?.reduce((acc: any, item: any) => {
                        return [...acc, item.testCaseId];
                    }, []),
                    'testCaseId',
                )}
            />
        </div>
    );
};

export default TemplateSelectionTable;
