import './templateSelection.scss';

import { SessionStatus, Update } from 'constants/constants';
import { useAppDispatch } from 'store';

import ButtonComponent from 'app/components/nds/button/button';
import InputComponent from 'app/components/nds/input/input';
import { NexusCard, NexusIcon } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import SearchComponent from 'app/components/nds/search/search';
import TemplateSelectionTable from './templateSelectionTable';
import { stepperRoutes } from 'constants/stepperConstants';
import { useTranslation } from 'react-i18next';
import {
    saveTestSessionData,
    getAllCanonicalModels,
    updateTestSession,
    updateCurrentTestSession,
    updateUserFlow,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import Select from 'app/components/nds/select/select';
import { currentTestSession, canonicalModels } from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';
import ResetIcon from '@nexus/core/dist/assets/icons/media/ic_replay_24px.svg';
import TextareaComponent from 'app/components/nds/textarea/textarea';

const TemplateSelection: React.FC = () => {
    const [t] = useTranslation('lang');
    const dispatch = useAppDispatch();
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [selectedDataSource, setSelectedDataSource] = useState<Array<number>>();
    const canonicalModelsData = useSelector(canonicalModels);
    const testSession = useSelector(currentTestSession);
    const { userFlow, testSessionTestCases } = testSession || {};
    const [testSessionName, setTestSessionName] = useState<string>(testSession?.testSessionName || '');
    const [testSessionDescription, setTestSessionDescription] = useState<string>(
        testSession?.testSessionDescription || '',
    );
    const dataSourceDropDownData = canonicalModelsData?.reduce((acc: any[], item: any) => {
        return [...acc, { label: item.name, value: item.id }];
    }, []);
    useEffect(() => {
        if (!canonicalModelsData || canonicalModelsData.length === 0) {
            dispatch(getAllCanonicalModels());
        }
    }, []);
    const handleNextButton = async () => {
        // testTemplateId is used while creating a new test session as case id is not available
        // testSession?.testCase?.testCaseId is used while updating a test session
        const payload = {
            description: testSessionDescription,
            draftStatus: SessionStatus.step3,
            isDraft: testSession.isDraft === false ? false : true,
            name: testSessionName,
            dateTo: testSession.dateTo,
            dateFrom: testSession.dateFrom,
            testSessionTestCases: testSessionTestCases.map((testCase: any) => {
                return { testCaseId: testCase.testCaseId, testCaseParameters: testCase.testCaseParameters };
            }),
        };
        if (userFlow === Update) {
            dispatch(updateTestSession({ id: testSession.testSessionId, testSessionRequest: payload })).then(
                (response: any) => {
                    if (response.payload?.status === 200) {
                        dispatch(updateActiveStep(stepperRoutes.step_3));
                    }
                },
            );
        } else {
            payload.testSessionTestCases = testSessionTestCases.reduce((acc: any, item: any) => {
                return [...acc, { testCaseId: item.testCaseId }];
            }, []);
            dispatch(saveTestSessionData(payload)).then((response: any) => {
                if (response.payload?.status === 201) {
                    dispatch(updateUserFlow(Update));
                    const { pathname } = window.location;
                    window.history.pushState('', document.title, `${pathname}/${response.payload.data.id}`);
                }
            });
        }
        return;
    };
    const handleBackButton = () => {
        dispatch(updateActiveStep(stepperRoutes.step_1));
    };
    const isButtonDisabled = () => {
        return !(testSessionName && testSessionName?.trim()?.length > 0 && testSessionTestCases.length > 0);
    };

    let searchTimeout: NodeJS.Timeout | null = null;
    const handleSearchText = (event: React.FormEvent<HTMLNexusInputElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        let searchStr = target.value?.trim();
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        // debouncing added
        searchTimeout = setTimeout(() => {
            setSearchKeyword(searchStr);
        }, 500);
    };

    const handleReset = () => {
        setSelectedDataSource(undefined);
        dispatch(updateCurrentTestSession({ testSessionTestCases: [] }));
    };

    return (
        <div data-testid='template-selection'>
            <NexusCard className='nexus-card-border-0 nexus-p-3'>
                <>
                    <div className='title-filter-container'>
                        <div className='nexus-subtitle nexus-mt-2  nexus-col-2xs-2 nexus-col-xs-2 nexus-col-sm-2 nexus-col-md-2 nexus-col-lg-4 nexus-col-xl-4 nexus-col-2xl-4'>
                            <div className='nexus-row'>
                                <div className='circle nexus-ml-1'>1</div>
                                <div className='nexus-subtitle nexus-ml-2'>{t('templateSelection.header1')}</div>
                                <div className='nexus-caption-copy nexus-mb-2 sub-heading'>
                                    {t('templateSelection.subHeader1')}
                                </div>
                            </div>
                        </div>
                        <div className='table-filters'>
                            {selectedDataSource && (
                                <div className='nexus-row nexus-mt-1 left-align'>
                                    <NexusIcon src={ResetIcon} className='nexus-mt-1' size='sm'></NexusIcon>
                                    <div
                                        className='nexus-body-sm reset'
                                        onClick={handleReset}
                                        data-testid='reset-button'
                                    >
                                        <u>{t('dataSelection.resetDataSource')}</u>
                                    </div>
                                </div>
                            )}
                            <div className='nexus-ml-2'>
                                <Select
                                    key={selectedDataSource?.[0]}
                                    name={'data source'}
                                    selectedValue={
                                        dataSourceDropDownData.find(
                                            (item: any) => item.value === selectedDataSource?.[0],
                                        )?.label || t('templateSelection.selectDataSource')
                                    }
                                    options={dataSourceDropDownData}
                                    customOnChange={(id: number) => {
                                        setSelectedDataSource([id]);
                                        dispatch(
                                            updateCurrentTestSession({
                                                testSessionTestCases: [],
                                            }),
                                        );
                                    }}
                                    classes='data-source-select'
                                />
                            </div>
                            <SearchComponent
                                placeholder={t('riskControlTestcase.search')}
                                onInputSearchValue={(event: React.FormEvent<HTMLNexusInputElement>) =>
                                    handleSearchText(event)
                                }
                                initialValue={searchKeyword}
                                extraClass='search-box'
                            />
                        </div>
                    </div>

                    <TemplateSelectionTable
                        searchKeyword={searchKeyword}
                        dataSourceId={selectedDataSource}
                        checkedItems={testSessionTestCases?.reduce((acc: any, item: any) => {
                            return [...acc, item.testCaseId];
                        }, [])}
                    />
                </>
                {/* )} */}
                <div className='nexus-row'>
                    <div className='nexus-mt-2 nexus-ml-1 circle'>2</div>
                    <div className='nexus-subtitle nexus-mx-2 nexus-mt-2'>{t('templateSelection.header2')}</div>
                </div>
                <div className='nexus-row'>
                    <div className='nexus-col-lg-4'>
                        <div className='nexus-caption-copy nexus-mt-1 nexus-mb-2 nexus-ml-4'>
                            {t('templateSelection.subHeader2')}
                        </div>
                        <div className='nexus-ml-4'>
                            <div className='nexus-data nexus-mb-1' data-testid='label-test-session-name'>
                                {t('templateSelection.testSessionName')}
                            </div>
                            <InputComponent
                                type='text'
                                placeholder={t('templateSelection.addText')}
                                data-testid='input-textbox-projectName'
                                initialValue={testSession?.testSessionName || testSessionName}
                                onChange={(event) => {
                                    setTestSessionName(event.target.value);
                                }}
                                extraClass='input-width'
                                disabled={testSession?.testSessionName?.length > 0}
                            />
                        </div>
                    </div>
                    <div className='nexus-col-lg-4'>
                        <div className='nexus-caption-copy nexus-mt-1 nexus-mb-2'>
                            {t('templateSelection.subHeader3')}
                        </div>
                        <div className=''>
                            <div className='nexus-data nexus-mb-1' data-testid='label-test-session-name'>
                                {t('templateSelection.testSessionDescription')}
                            </div>
                            <TextareaComponent
                                placeholder={t('templateSelection.addText')}
                                data-testid='input-textbox-projectName'
                                value={testSession?.testSessionDescription || testSessionDescription}
                                onChange={(event: any) => {
                                    setTestSessionDescription(event.target.value);
                                }}
                                styles='input-width'
                            />
                        </div>
                    </div>
                </div>
                <div className='nexus-flex-row-reverse nexus-mt-3'>
                    <div className='nexus-row'>
                        <div className='nexus-mr-2'>
                            <ButtonComponent
                                type={['nexus-btn nexus-rhythm-top-5']}
                                label={t('buttons.back')}
                                disabled={userFlow === Update}
                                click={handleBackButton}
                            />
                        </div>
                        <div className='next-button-styles'>
                            <ButtonComponent
                                extraClass='nexus-btn-default'
                                type={['nexus-btn-primary nexus-rhythm-top-5']}
                                label={t('buttons.next')}
                                disabled={isButtonDisabled()}
                                click={handleNextButton}
                            />
                        </div>
                    </div>
                </div>
            </NexusCard>
        </div>
    );
};

export default TemplateSelection;
