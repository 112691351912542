import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import ButtonComponent from 'app/components/nds/button/button';
import FileUploadComponent from 'app/components/nds/fileupload/fileUpload';
import LoaderComponent from 'app/components/nds/loader/loader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IFileUpload {
    closeModal?: any;
    uploadPatch?: (dataFileId: any) => void;
    isLoading?: boolean;
    handleDownloadFile?: () => void;
    handleUpload: (data: any) => void;
}

const FileUpload = ({ closeModal, handleDownloadFile, handleUpload, isLoading }: IFileUpload) => {
    const [queue, setQueue] = useState<Array<any>>([]);
    const [t] = useTranslation('lang');

    const returnFileQueue = (fileQueue: any) => {
        setQueue(fileQueue);
    };

    const removeFileQueue = () => {
        setQueue([]);
    };

    return (
        <div data-testid='Upload-File-Modal'>
            {isLoading && <LoaderComponent show={isLoading} fullscreen={true} />}
            <div className='nexus-body'>{t('dataSelection.modalHeader')}</div>
            <div className='nexus-row'>
                <div className='circle nexus-mt-2 nexus-ml-1'>1</div>
                <div className='nexus-subtitle nexus-mx-2 nexus-mt-2'>{t('dataSelection.modalDownload')}</div>
            </div>
            <div className='nexus-caption-copy adjust-left nexus-mt-1'>{t('dataSelection.modalDownload1')}</div>
            <div className='adjust-left nexus-mt-1'>
                <ButtonComponent
                    click={handleDownloadFile}
                    type={['nexus-btn nexus-btn-medium']}
                    label={t('buttons.download')}
                />
            </div>
            <div className='nexus-row nexus-mt-3'>
                <div className='circle nexus-mt-2 nexus-ml-1'>2</div>
                <div className='nexus-subtitle nexus-mx-2 nexus-mt-2'>{t('dataSelection.modalUpload')}</div>
            </div>
            <div className='adjust-input-box'>
                <FileUploadComponent
                    accept='.csv'
                    cancelBtn={closeModal}
                    returnFileQueue={returnFileQueue}
                    removeFileQueue={removeFileQueue}
                />
            </div>
            <div className='nexus-flex-row-reverse nexus-mt-3'>
                <div className='nexus-row'>
                    <div className='nexus-mr-2'>
                        <ButtonComponent
                            type={['nexus-btn nexus-rhythm-top-5']}
                            label={t('buttons.cancel')}
                            click={closeModal}
                        />
                    </div>
                    <div className='nexus-mr-1'>
                        <ButtonComponent
                            extraClass='nexus-btn-default'
                            type={['nexus-btn-primary nexus-rhythm-top-5']}
                            label={t('buttons.uploadFile')}
                            disabled={!queue.length}
                            click={() => handleUpload(queue)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileUpload;
