/* eslint-disable sort-keys */
import { INVALID, VALID, error } from 'constants/constants';
import { stepperRoutes } from 'constants/stepperConstants';
import { addMessage, removeMessage } from 'utils/actionUtils';
import { createAppSlice } from 'store/sliceHelper';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    SearchTestSessionPage,
    TestExecutionStatistic,
    TestSessionApiFactory,
    TestSessionLandingApiFactory,
    TestSessionRequest,
    TestSessionResponse,
    TestSessionStatus,
    PrcDataDTO,
    PRCDataHierarchyApiFactory,
    TestCaseStatus,
    SearchTestCasePage,
    TestCaseApiFactory,
    CanonicalModelResponse,
    CanonicalModelsApiFactory,
    FetchTestSessionResponse,
    TestCasePage,
    DataInputFileResponse,
    DataInputFileApiFactory,
    TestTemplatePreviewRequest,
    StreamingResponseBody,
    DownloadFileApiFactory,
    TestSessionRunsResponsePage,
} from 'app/api/atAPI';
import { atApiConfig } from 'constants/apiConstants';

const testSessionLandingApiFactory = TestSessionLandingApiFactory(atApiConfig);
const testSessionApiFactory = TestSessionApiFactory(atApiConfig);
const prcDataHierarchyApiFactory = PRCDataHierarchyApiFactory(atApiConfig);
const testCaseApiFactory = TestCaseApiFactory(atApiConfig);
const canonicalModelsApiFactory = CanonicalModelsApiFactory(atApiConfig);
const dataInputFileApiFactory = DataInputFileApiFactory(atApiConfig);
const downloadFileApiFactory = DownloadFileApiFactory(atApiConfig);

export const INITIAL_STATE_TEST_SESSION: any = {
    canonicalModelsData: [],
    checkedItems: [],
    controlData: [],
    currentTestSession: {
        canonicalModelData: {},
        testSessionTestCases: [],
    },
    isLoading: false,
    lobData: [],
    messages: [],
    processData: [],
    riskData: [],
    sectorData: [],
    templateSelectionData: {},
    testSessionCountInfo: {},
    testSessionTableData: {},
};

type TestSessionState = Readonly<typeof INITIAL_STATE_TEST_SESSION>;
export const testSessionSliceReducer = createAppSlice({
    initialState: INITIAL_STATE_TEST_SESSION as TestSessionState,
    name: 'testSessionData',
    reducers: (create) => {
        return {
            getTestSessionCountInfo: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: TestExecutionStatistic } =
                            await testSessionLandingApiFactory.getTestExecutionStatisticForTestSession();
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTestSessionCountInfo');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'getTestSessionCountInfo',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getTestSessionCountInfo');
                        state.testSessionCountInfo = action.payload?.data;
                    },
                },
            ),
            saveTestSessionData: create.asyncThunk(
                async (body: TestSessionRequest, { rejectWithValue }) => {
                    try {
                        const response: { data: TestSessionResponse; status: number } =
                            await testSessionApiFactory.saveTestSessionData(body);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'saveTestSessionData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'saveTestSessionData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.currentTestSession.draftStatus = action.payload?.data?.draftStatus;
                        state.currentTestSession.testSessionTestCases = action.payload?.data?.testSessionTestCases;
                        state.currentTestSession.testSessionId = action.payload?.data?.id;
                        state.currentTestSession.testSessionIsDraft = action.payload?.data?.isDraft;
                        state.currentTestSession.testSessionName = action.payload?.data?.name;
                        state.currentTestSession.testSessionDescription = action.payload?.data?.description;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'saveTestSessionData',
                                message: 'Test Session created successfully',
                                module: 'session',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            getTestSessionTableData: create.asyncThunk(
                async (
                    body: {
                        status?: TestSessionStatus;
                        search?: string;
                        lastRunDateFrom?: string | null;
                        lastRunDateTo?: string | null;
                        page?: number;
                        size?: number;
                        sort?: Array<string>;
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: SearchTestSessionPage; status: number } =
                            await testSessionApiFactory.searchTestSession(
                                body.status,
                                body?.search,
                                body.lastRunDateFrom,
                                body.lastRunDateTo,
                                body.page,
                                body.size,
                                body.sort,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTestSessionTableData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getTestSessionTableData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getTestSessionTableData');
                        state.testSessionTableData = action.payload?.data;
                    },
                },
            ),
            getTemplateSelectionData: create.asyncThunk(
                async (
                    body: {
                        status?: TestCaseStatus;
                        controlIds?: Array<string>;
                        canonicalModelIds?: Array<number>;
                        testCaseCreationDateFrom?: string | null;
                        testCaseCreationDateTo?: string;
                        search?: string;
                        page?: number;
                        size?: number;
                        sort?: Array<string>;
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: SearchTestCasePage; status: number } =
                            await testCaseApiFactory.searchTestTemplate(
                                body.status,
                                body.controlIds,
                                undefined,
                                body.testCaseCreationDateFrom,
                                body.testCaseCreationDateTo,
                                body.canonicalModelIds,
                                body.search,
                                body.page,
                                body.size,
                                body.sort,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTemplateSelectionData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getTemplateSelectionData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'templateSelectionData');
                        state.templateSelectionData = action.payload?.data;
                    },
                },
            ),
            getSectorDropdownData: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getAllSectors();
                        if (response?.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'sectorData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'sectorData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'sectorData');
                        state.sectorData = action.payload?.data;
                    },
                },
            ),
            getLobDropdownData: create.asyncThunk(
                async (sectorId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getLobs(sectorId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'lobData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'lobData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'lobData');
                        state.lobData = action.payload?.data;
                    },
                },
            ),
            getProcessDropdownData: create.asyncThunk(
                async (lobId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getProcesses(lobId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'processData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'processData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'processData');
                        state.processData = action.payload?.data;
                    },
                },
            ),
            getRiskDropdownData: create.asyncThunk(
                async (processId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getRisks([''], processId);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'riskData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'riskData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'riskData');
                        state.riskData = action.payload?.data;
                    },
                },
            ),
            getControlDropdownData: create.asyncThunk(
                async (riskIds: Array<string>, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PrcDataDTO>; status: number } =
                            await prcDataHierarchyApiFactory.getControls([''], riskIds);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'controlData');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'controlData',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'controlData');
                        state.controlData = action.payload?.data;
                    },
                },
            ),
            getAllCanonicalModels: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<CanonicalModelResponse>; status: number } =
                            await canonicalModelsApiFactory.fetchAllCanonicalModelInfo();
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getAllCanonicalModels');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getAllCanonicalModels',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getAllCanonicalModels');
                        state.canonicalModelsData = action.payload?.data;
                    },
                },
            ),
            getTestSession: create.asyncThunk(
                async (id: number, { rejectWithValue }) => {
                    try {
                        const response: { data: FetchTestSessionResponse; status: number } =
                            await testSessionApiFactory.fetchTestSessionDetails(id);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTestSession');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getTestSession',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        const updatedTestCases = action.payload?.data?.testSessionTestCases.map((testCase: any) => {
                            const actionData = state.currentTestSession.testSessionTestCases?.find(
                                (item: any) => item.testCaseId === testCase.testCaseId,
                            );
                            if (actionData) {
                                return {
                                    ...actionData,
                                    ...testCase,
                                };
                            }
                            return testCase;
                        });
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getTestSession');
                        state.currentTestSession.draftStatus = action.payload?.data?.isDraft
                            ? action.payload?.data?.draftStatus
                            : stepperRoutes.step_5;
                        state.currentTestSession.scheduler = action.payload?.data?.scheduler;
                        state.currentTestSession.testSessionTestCases = updatedTestCases;
                        state.currentTestSession.testSessionId = action.payload?.data?.id;
                        state.currentTestSession.dateTo = action?.payload?.data?.dateTo;
                        state.currentTestSession.dateFrom = action?.payload?.data?.dateFrom;
                        state.currentTestSession.testSessionIsDraft = action.payload?.data?.isDraft;
                        state.currentTestSession.testSessionName = action.payload?.data?.name;
                        state.currentTestSession.uploadedDataSourceData = {
                            ...state.currentTestSession.uploadedDataSourceData,
                            ...action.payload?.data?.dataInputFile,
                        };
                        state.currentTestSession.testSessionDescription = action.payload?.data?.description;
                    },
                },
            ),
            getTestCases: create.asyncThunk(
                async (ids: number[], { rejectWithValue }) => {
                    try {
                        const response: { data: TestCasePage; status: number } =
                            await testCaseApiFactory.fetchTestCaseCollection(ids);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getTestCases');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getTestCases',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        const data = action.payload.data.content || [];
                        const updatedTestSessionTestCases = state.currentTestSession.testSessionTestCases.map(
                            (item: any) => {
                                const testCase = data.find((testCase: any) => testCase.id === item.testCaseId);
                                return {
                                    ...item,
                                    ...testCase,
                                    testCaseParameters: {
                                        execution_parameters:
                                            (testCase?.scriptConfiguration as any)?.execution_parameters || {},
                                        ...item.testCaseParameters,
                                    },
                                };
                            },
                        );
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getTestCases');
                        state.currentTestSession.testSessionTestCases = updatedTestSessionTestCases;
                    },
                },
            ),
            getCanonicalModelById: create.asyncThunk(
                async (id: string, { rejectWithValue }) => {
                    try {
                        const response: { data: CanonicalModelResponse; status: number } =
                            await canonicalModelsApiFactory.fetchCanonicalModelInfo(parseInt(id));
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getcanonicalModelById');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getcanonicalModelById',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getcanonicalModelById');
                        state.currentTestSession.canonicalModelData = action.payload?.data;
                    },
                },
            ),
            deleteTestSession: create.asyncThunk(
                async (testSessionId: number, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } = await testSessionApiFactory.deleteTestSession(
                            testSessionId,
                        );

                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'deleteTestSession');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'deleteTestSession',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'deleteTestSession',
                                message: 'Test Session deleted successfully',
                                module: 'session',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            deleteSelectedSession: create.asyncThunk(
                async (ids: Array<number>, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } = await testSessionApiFactory.deleteTestSessions(
                            ids,
                        );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'deleteSelectedSession');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'deleteSelectedSession',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'deleteSelectedSession',
                                message: 'Selected Test Sessions deleted successfully',
                                module: 'session',
                                type: 'success',
                            },
                            removeToastMessage,
                        );
                        state.checkedItems = [];
                    },
                },
            ),
            getTestSessionDataSourceTemplate: create.asyncThunk(async (id: string, { rejectWithValue }) => {
                try {
                    const response: { data: File; status: number } =
                        await canonicalModelsApiFactory.downloadTemplateForCanonicalModel(parseInt(id));
                    if (response.status >= 400) {
                        return rejectWithValue(response);
                    }
                    return response;
                } catch (error: any) {
                    return rejectWithValue(error);
                }
            }),
            uploadDataSourceTemplate: create.asyncThunk(
                async (data: { id: number; file: File }, { rejectWithValue }) => {
                    try {
                        const response: { data: DataInputFileResponse; status: number } =
                            await dataInputFileApiFactory.uploadDataInputFile(data.id, data.file);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'uploadDataSourceTemplate');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'uploadDataSourceTemplate',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'uploadDataSourceTemplate');
                        state.currentTestSession.uploadedDataSourceData = action.payload?.data;
                    },
                },
            ),
            dataSourceTemplateInfo: create.asyncThunk(
                async (dataFileId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: DataInputFileResponse; status: number } =
                            await dataInputFileApiFactory.getDataInputFile(parseInt(dataFileId));
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'dataSourceTemplateInfo');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'dataSourceTemplateInfo',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'dataSourceTemplateInfo');
                        state.currentTestSession.uploadedDataSourceData = action.payload?.data;
                    },
                },
            ),
            getExpPreview: create.asyncThunk(
                async (
                    {
                        testTemplatePreviewRequest,
                        testCaseId,
                    }: { testTemplatePreviewRequest: TestTemplatePreviewRequest; testCaseId: number },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: string; status: number } = await testCaseApiFactory.generatePreview(
                            testTemplatePreviewRequest,
                        );
                        if (response.status >= 400) {
                            const rejectedResponse = { ...response, previewStatus: INVALID, testCaseId };
                            return rejectWithValue(rejectedResponse);
                        }
                        return {
                            data: response.data,
                            previewStatus: VALID,
                            status: response.status,
                            testCaseId: testCaseId,
                        };
                    } catch (error: any) {
                        const rejectedResponse = { ...error, previewStatus: INVALID, testCaseId };
                        return rejectWithValue(rejectedResponse);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getExpPreview');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        const testCases = state.currentTestSession.testSessionTestCases;
                        const updatedTestCases = testCases.map((testCase: any) => {
                            if (testCase.testCaseId === action.payload.testCaseId) {
                                return {
                                    ...testCase,
                                    previewStatus: action.payload.previewStatus,
                                };
                            }
                            return testCase;
                        });
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'getExpPreview',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                        state.currentTestSession.testSessionTestCases = updatedTestCases;
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        const testCases = state.currentTestSession.testSessionTestCases;
                        const updatedTestCases = testCases.map((testCase: any) => {
                            if (testCase.testCaseId === action.payload.testCaseId) {
                                return {
                                    ...testCase,
                                    testCasePreview: action.payload.data,
                                    previewStatus: action.payload.previewStatus,
                                };
                            }
                            return testCase;
                        });
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'getExpPreview');
                        state.currentTestSession.testSessionTestCases = updatedTestCases;
                    },
                },
            ),
            getFile: create.asyncThunk(async (downloadLink: string, { rejectWithValue }) => {
                try {
                    const response: { data: StreamingResponseBody; status: number } =
                        await downloadFileApiFactory.downloadFile(downloadLink);
                    if (response.status >= 400) {
                        return rejectWithValue(response);
                    }
                    return response;
                } catch (error: any) {
                    return rejectWithValue(error);
                }
            }),
            runTestSession: create.asyncThunk(
                async (testSessionId: number, { rejectWithValue }) => {
                    try {
                        const response: { data: void; status: number } = await testSessionApiFactory.runTestSession(
                            testSessionId,
                        );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'runTestSession',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                },
            ),
            fetchTestSessionRuns: create.asyncThunk(
                async (
                    body: { testSessionId?: any; page?: number; size?: number; sort?: Array<string> },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: TestSessionRunsResponsePage; status: number } =
                            await testSessionApiFactory.fetchTestSessionRuns(
                                body.testSessionId,
                                body.page,
                                body.size,
                                body.sort,
                            );
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'fetchTestSessionRuns');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                id: 'fetchTestSessionRuns',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: error,
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = removeMessage(state, 'fetchTestSessionRuns');
                        state.currentTestSession.testExecutionData = action.payload?.data;
                    },
                },
            ),
            updateTestSession: create.asyncThunk(
                async (body: { id: number; testSessionRequest: TestSessionRequest }, { rejectWithValue }) => {
                    try {
                        const response: { data: TestSessionResponse; status: number } =
                            await testSessionApiFactory.updateTestSessionData(body.id, body.testSessionRequest);
                        if (response.status >= 400) {
                            return rejectWithValue(response);
                        }
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    pending: (state: typeof INITIAL_STATE_TEST_SESSION) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'updateTestSession');
                    },
                    rejected: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        state.isLoading = false;
                        state.messages = addMessage(
                            state,
                            {
                                autoClose: 5000,
                                id: 'updateTestSession',
                                message: action?.payload?.response?.data?.detail,
                                module: 'session',
                                type: 'error',
                            },
                            removeToastMessage,
                        );
                    },
                    fulfilled: (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                        const updatedTestCases = state.currentTestSession.testSessionTestCases.map((testCase: any) => {
                            const updatedTestCase = action.payload?.data?.testSessionTestCases.find(
                                (item: any) => item.testCaseId === testCase.testCaseId,
                            );
                            let execution_parameters;
                            if (updatedTestCase?.testCaseParameters?.execution_parameters) {
                                execution_parameters = {
                                    ...updatedTestCase?.testCaseParameters?.execution_parameters,
                                };
                            } else {
                                execution_parameters = {
                                    ...testCase?.scriptConfiguration?.execution_parameters,
                                };
                            }
                            return {
                                ...testCase,
                                ...updatedTestCase,
                                scriptConfiguration: {
                                    ...testCase.scriptConfiguration,
                                    execution_parameters,
                                },
                            };
                        });
                        state.isLoading = false;
                        state.currentTestSession.draftStatus = !action.payload?.data?.isDraft
                            ? state.currentTestSession?.draftStatus || stepperRoutes.step_5
                            : action.payload?.data?.draftStatus;
                        state.currentTestSession.testSessionTestCases = updatedTestCases;
                        state.currentTestSession.testSessionIsDraft = action.payload?.data?.isDraft;
                        state.currentTestSession.uploadedDataSourceData = {
                            ...state.currentTestSession.uploadedDataSourceData,
                            ...action.payload?.data?.dataInputFile,
                        };
                        state.currentTestSession.dateFrom = action.payload?.data?.dateFrom;
                        state.currentTestSession.dateTo = action.payload?.data?.dateTo;
                        state.messages = removeMessage(state, 'updateTestSession');
                    },
                },
            ),
            updateTestSessionTestCaseById: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    const testCases = state.currentTestSession.testSessionTestCases;
                    const updatedTestCases = testCases.map((testCase: any) => {
                        if (testCase.testCaseId === action.payload.testCaseId) {
                            return {
                                ...testCase,
                                ...action.payload.data,
                            };
                        }
                        return testCase;
                    });
                    state.currentTestSession.testSessionTestCases = updatedTestCases;
                },
            ),
            resetUploadedDataSource: create.reducer<any>((state: typeof INITIAL_STATE_TEST_SESSION) => {
                state.currentTestSession.uploadedDataSourceData = {};
            }),
            reset: create.reducer<any>((state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                const key = action.payload as string;
                state[key] = INITIAL_STATE_TEST_SESSION[key];
            }),
            updateCurrentTestSession: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    state.currentTestSession = { ...state.currentTestSession, ...action.payload };
                },
            ),
            updateUserFlow: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    state.currentTestSession.userFlow = action.payload;
                },
            ),
            showToastMessage: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    state.messages = addMessage(state, { ...action.payload, module: 'session' }, removeToastMessage);
                },
            ),
            resetAll: create.reducer<any>(() => INITIAL_STATE_TEST_SESSION),
            updateCheckedItems: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    state.checkedItems = action.payload;
                },
            ),
            updateActiveStep: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    state.currentTestSession.draftStatus = action.payload;
                },
            ),
            removeToastMessage: create.reducer<any>(
                (state: typeof INITIAL_STATE_TEST_SESSION, action: PayloadAction<any>) => {
                    state.messages = removeMessage(state, action.payload);
                },
            ),
        };
    },
});

export const {
    getTestSessionCountInfo,
    saveTestSessionData,
    getTestSessionTableData,
    getSectorDropdownData,
    getTemplateSelectionData,
    getLobDropdownData,
    getProcessDropdownData,
    getRiskDropdownData,
    getControlDropdownData,
    getAllCanonicalModels,
    getTestSession,
    getTestCases,
    getCanonicalModelById,
    deleteTestSession,
    deleteSelectedSession,
    getTestSessionDataSourceTemplate,
    uploadDataSourceTemplate,
    dataSourceTemplateInfo,
    getExpPreview,
    getFile,
    runTestSession,
    fetchTestSessionRuns,
    updateTestSession,
    updateTestSessionTestCaseById,
    resetUploadedDataSource,
    reset,
    updateCurrentTestSession,
    updateUserFlow,
    showToastMessage,
    updateCheckedItems,
    resetAll,
    updateActiveStep,
    removeToastMessage,
} = testSessionSliceReducer.actions;
export default testSessionSliceReducer.reducer;
