import './testHierarchy.scss';

import { NexusCard, NexusCardBody } from '@nexus/react';
import { getDropdownValues, getMultiSelectValues, getUniqueLabelKey } from 'services/services';
import { useAppDispatch } from 'store';
import { useEffect, useRef, useState } from 'react';
import { updateCurrentTestSession } from '../../redux/testSessionReducer';
import { IButton } from '../../interfaces';
import MultiSelect from 'app/components/multiselect/multiSelect';
import Select from 'app/components/nds/select/select';
import { getHomeUrl } from 'utils/redirectionUtils';
import { stepperRoutes } from 'constants/stepperConstants';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    getSectorDropdownData,
    getLobDropdownData,
    getProcessDropdownData,
    getRiskDropdownData,
    getControlDropdownData,
    reset,
    updateActiveStep,
} from '../../redux/testSessionReducer';
import {
    sectorDataList,
    lobDataList,
    processDataList,
    riskDataList,
    controlDataList,
} from '../../redux/testSessionSelectors';
import { useSelector } from 'react-redux';

const TestParameters = () => {
    const [t] = useTranslation('lang');
    const bottomRef: any = useRef(null);
    const dispatch = useAppDispatch();

    const sectorData = useSelector(sectorDataList);
    const lobData = useSelector(lobDataList);
    const processData = useSelector(processDataList);
    const riskData = useSelector(riskDataList);
    const controlData = useSelector(controlDataList);
    const navigate = useNavigate();
    const [sector, setSector] = useState<{ sector: string; id: string }>({ sector: '', id: '' });
    const [lob, setLob] = useState<{ lob: string; id: string }>({ lob: '', id: '' });
    const [process, setProcess] = useState<{ process: string; id: string }>({ process: '', id: '' });
    const [risk, setRisk] = useState<any[]>([]);
    const [control, setControl] = useState<any[]>([]);

    useEffect(() => {
        dispatch(getSectorDropdownData());
    }, []);
    useEffect(() => {
        if (sector.id) {
            dispatch(reset('lobData'));
            dispatch(reset('processData'));
            dispatch(reset('riskData'));
            dispatch(reset('controlData'));
            setLob({ lob: '', id: '' });
            setProcess({ process: '', id: '' });
            setRisk([]);
            setControl([]);
            dispatch(getLobDropdownData(sector.id));
        }
    }, [sector]);
    useEffect(() => {
        if (lob.id) {
            dispatch(reset('processData'));
            dispatch(reset('riskData'));
            dispatch(reset('controlData'));
            setProcess({ process: '', id: '' });
            setRisk([]);
            setControl([]);
            dispatch(getProcessDropdownData(lob.id));
        }
    }, [lob]);
    useEffect(() => {
        if (process.id) {
            dispatch(reset('riskData'));
            dispatch(reset('controlData'));
            setRisk([]);
            setControl([]);
            dispatch(getRiskDropdownData(process.id));
        }
    }, [process]);
    useEffect(() => {
        if (risk.length > 0) {
            dispatch(reset('controlData'));
            setControl([]);
            dispatch(getControlDropdownData(risk));
        }
    }, [risk]);
    const handleSeeAllTemplates = () => {
        dispatch(updateActiveStep(stepperRoutes.step_2));
    };
    const handleBackButton = () => {
        navigate(getHomeUrl());
    };
    const handleNextButton = () => {
        dispatch(
            updateCurrentTestSession({
                prc: {
                    control,
                    lob,
                    process,
                    risk,
                    sector,
                },
            }),
        );
        dispatch(updateActiveStep(stepperRoutes.step_2));
    };
    const eqButtonList: IButton[] = [
        {
            buttonDisabled: !sector || !lob || !process || risk?.length === 0 || control?.length === 0,
            buttonStyles: 'nexus-btn-primary',
            clickButton: handleNextButton,
            label: t('buttons.next'),
            type: 'button',
        },

        {
            buttonDisabled: false,
            buttonStyles: '',
            clickButton: handleBackButton,
            label: t('buttons.back'),
            type: 'button',
        },
    ];
    const selectDropdownValue = (inputValue: string) => {
        return inputValue?.length ? inputValue : t('dataSelection.pleaseSelect');
    };
    return (
        <>
            <div className='nexus-my-2 nexus-py-2' data-testid='test-parameters'>
                <NexusCard className='card-header-styles-sub-header nexus-p-1'>
                    <NexusCardBody>
                        <div className={`nexus-row display-align`}>
                            <div className='nexus-subtitle nexus-mx-2 nexus-mt-2'>{t('testParameters.header')}</div>
                            <div
                                className={`nexus-subtitle nexus-mt-2 nexus-mr-2 see-all`}
                                data-testid={'see-all-template-button'}
                                onClick={() => handleSeeAllTemplates()}
                            >
                                {t('testParameters.seeAllTemplates')}
                            </div>
                        </div>
                        <div className='nexus-caption-copy nexus-mt-2 nexus-mx-2'>{t('testParameters.body')}</div>
                        <div className='nexus-col' data-testid='sector-container'>
                            <div className='nexus-mx-2 nexus-mt-2 nexus-col-lg-3 nexus-col-xl-3'>
                                <span className='nexus-data'>{t('testParameters.sector')}</span>
                                <Select
                                    name={'sector'}
                                    key={sectorData?.[0] ? sectorData[0]?.name : 'sector'}
                                    selectedValue={selectDropdownValue(sector?.sector)}
                                    options={getDropdownValues(sectorData) || []}
                                    customOnChange={(data: any) => {
                                        const sectorId = sectorData?.find((item: any) => item?.name === data)?.id;
                                        setSector({ sector: data, id: sectorId });
                                    }}
                                    disabledOption={t('dataSelection.pleaseSelect')}
                                />
                            </div>
                            <div className='nexus-mx-2 nexus-mt-2 nexus-col-lg-3 nexus-col-xl-3'>
                                <span className='nexus-data'>{t('testParameters.lineofBusiness')}</span>
                                <Select
                                    disabled={!sector?.id}
                                    name={'lob'}
                                    key={lobData[0] ? lobData[0].name : sector.id}
                                    selectedValue={selectDropdownValue(lob.lob)}
                                    options={getDropdownValues(lobData) || []}
                                    customOnChange={(data: any) => {
                                        const lobId = lobData?.find((item: any) => item?.name === data)?.id;
                                        setLob({ lob: data, id: lobId });
                                    }}
                                    disabledOption={t('dataSelection.pleaseSelect')}
                                />
                            </div>
                            <div className='nexus-mx-2 nexus-mt-2 nexus-col-lg-3 nexus-col-xl-3'>
                                <span className='nexus-data'>{t('testParameters.process')}</span>
                                <Select
                                    disabled={!lob?.id}
                                    name={'process'}
                                    key={processData?.[0] ? processData[0].name : lob}
                                    selectedValue={selectDropdownValue(process?.process)}
                                    options={getDropdownValues(processData) || []}
                                    customOnChange={(data: any) => {
                                        const processId = processData?.find((item: any) => item?.name === data)?.id;

                                        setProcess({ process: data, id: processId });
                                    }}
                                    disabledOption={t('dataSelection.pleaseSelect')}
                                />
                            </div>
                            <div className='nexus-mx-2 nexus-mt-2 nexus-col-lg-3 nexus-col-xl-3'>
                                <span className='nexus-data'>{t('testParameters.risk')}</span>
                                <MultiSelect
                                    disabled={!process.id}
                                    key={riskData?.[0] ? riskData[0].name : process}
                                    name='risk'
                                    options={getMultiSelectValues(riskData) || []}
                                    showSelectAll={true}
                                    delimiter='::'
                                    data-testid='custom-multi-select'
                                    attr-id='custom-multi-select-1'
                                    type='custom'
                                    multiple={true}
                                    onChange={(data) => {
                                        setRisk(data);
                                    }}
                                    selectAllOptions={false}
                                    extraClass='multi-select-styling'
                                    disabledOption={t('dataSelection.pleaseSelect')}
                                />
                            </div>
                            <div className='nexus-mx-2 nexus-mt-2 nexus-col-lg-3 nexus-col-xl-3'>
                                <span className='nexus-data'>{t('testParameters.control')}</span>
                                <MultiSelect
                                    disabled={risk.length === 0}
                                    key={controlData?.[0] ? controlData[0].name : process}
                                    name='control'
                                    options={getMultiSelectValues(controlData)}
                                    showSelectAll={true}
                                    delimiter='::'
                                    data-testid='custom-multi-select'
                                    attr-id='custom-multi-select-1'
                                    type='custom'
                                    multiple={true}
                                    onChange={(data) => {
                                        setControl(data);
                                    }}
                                    selectAllOptions={false}
                                    extraClass='multi-select-styling'
                                    disabledOption={t('dataSelection.pleaseSelect')}
                                />
                            </div>
                        </div>
                        <div className='nexus-flex-row-reverse nexus-mt-3'>
                            {eqButtonList.map(({ label, clickButton, type, buttonDisabled, buttonStyles }, index) => {
                                return (
                                    <button
                                        type={type}
                                        onClick={clickButton}
                                        className={`nexus-ml-4 nexus-btn ${buttonStyles}`}
                                        key={getUniqueLabelKey({ index, label })}
                                        disabled={buttonDisabled}
                                        data-testid={label}
                                    >
                                        {label}
                                    </button>
                                );
                            })}
                        </div>
                        <div ref={bottomRef} />
                    </NexusCardBody>
                </NexusCard>
            </div>
        </>
    );
};
export default TestParameters;
